import React from "react";
import { ModalsKeys } from "global";
import { Add } from "@mui/icons-material";
import { useModal } from "hooks/useModal";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { ProposalProductsCard } from "modules/desking";
import { ProductConfig, ProposalProductCardModes } from "modules/desking/types";
import { prisingConfigByVendor } from "../../../constants";
import { menuConstructorActions, menuConstructorSelectors } from "../../../model";
import { useAftermarketPermissions } from "../../../hooks/useAftermarketPermissions";

interface Props {
  isProductsLoading: boolean;
}

export const ProductsCard = ({ isProductsLoading }: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const isLoading = useSelector(menuConstructorSelectors.isLoading);
  const selectedMenuOption = useSelector(menuConstructorSelectors.selectedMenuOption) as any;
  const allProducts = useSelector(menuConstructorSelectors.proposalsProducts) as ProposalProduct[];
  const selectedProducts = useSelector(menuConstructorSelectors.selectedProposalsProducts) as ProposalProduct[];
  const proposalProductConfigurations = useSelector(menuConstructorSelectors.proposalProductConfigurations);
  const proposalProductConfigurationsInMenu = useSelector(menuConstructorSelectors.proposalProductConfigurationsInMenu);
  const selectedProductsFactsForCheck = useSelector(menuConstructorSelectors.selectedProductsFactsForCheck);

  const { handleOpen: handleProposalProductsDialogOpen } = useModal(ModalsKeys.ProposalProductsDialog);
  const { hasWriteAccess } = useAftermarketPermissions();

  const handleProductsDialogConfirm = (selectedProducts: ProposalProduct[]) => {
    dispatch(menuConstructorActions.setSelectedProducts(selectedProducts));
  };

  const handleAddToAllOptions = () => {
    dispatch(menuConstructorActions.addAllProductsToMenu());
  };

  const handleUpdateProductConfig = (productId: string, data: Partial<ProductConfig>) => {
    dispatch(menuConstructorActions.updateProductConfiguration({ data, productId }));
  };

  const handleUpdateProductConfigInMenu = (productId: string, data: Partial<ProductConfig>, menuName: string) => {
    dispatch(menuConstructorActions.updateProductConfigurationInMenu({ data, menuName, productId }));
  };

  const handleOpenProductsDialog = () => {
    handleProposalProductsDialogOpen({
      products: allProducts,
      onConfirm: handleProductsDialogConfirm,
      selectedProposalsProductsIds: selectedProducts?.map(p => p.proposalProductId),
    });
  };

  return (
    <CardContainer
      title="Products"
      isLoading={isLoading}
      actionBtn={
        hasWriteAccess && (
          <Button
            startIcon={<Add />}
            loadingPosition="start"
            loading={isProductsLoading}
            onClick={handleOpenProductsDialog}>
            {!!selectedProducts.length ? "Add more" : "Add product"}
          </Button>
        )
      }>
      <Box className={classes.container}>
        {selectedProducts.map(product => (
          <ProposalProductsCard
            type="simple"
            product={product}
            key={product.proposalProductId}
            mode={ProposalProductCardModes.Constructor}
            prisingConfigByVendor={prisingConfigByVendor}
            factsToCheck={selectedProductsFactsForCheck?.[product.proposalProductId]}
            proposalProductConfiguration={proposalProductConfigurations[product.proposalProductId] ?? {}}
            updateProductConfiguration={updateValues => {
              handleUpdateProductConfig(product.proposalProductId, updateValues);
            }}
          />
        ))}

        {!!selectedProducts.length && (
          <Tooltip title="Adds all products & their current configuration to every available menu option in the template">
            <Button variant="outlined" onClick={handleAddToAllOptions}>
              Apply Product(s) to All Options
            </Button>
          </Tooltip>
        )}

        {(selectedMenuOption?.products || []).map((product: ProposalProduct) => (
          <ProposalProductsCard
            type="forEdit"
            product={product}
            mode={ProposalProductCardModes.Constructor}
            key={`forEdit-${product.proposalProductId}`}
            prisingConfigByVendor={prisingConfigByVendor}
            factsToCheck={selectedProductsFactsForCheck[product.proposalProductId]}
            updateProductConfiguration={updateValues => {
              handleUpdateProductConfigInMenu(product.proposalProductId, updateValues, selectedMenuOption.name);
            }}
            proposalProductConfiguration={
              proposalProductConfigurationsInMenu?.[selectedMenuOption.name]?.[product.proposalProductId] ?? {}
            }
          />
        ))}
      </Box>
    </CardContainer>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "8px",
    display: "flex",
    maxHeight: "800px",
    overflowY: "auto",
    flexDirection: "column",
  },
});
